import classnames from 'classnames/bind';
import { IComponent } from '../../models/Models';
import styles from './Grid.module.scss';

interface IProps extends IComponent {
  type?: 'section' | 'nav' | 'article' | 'main';
  display?: 'inline-flex' | 'flex';
  wrap?: 'nowrap' | 'wrap';
  direction?: 'row' | 'column';
  content?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  align?: 'center' | 'flex-start' | 'flex-end';
  gap?: 'none' | 'small' | 'default' | 'medium' | 'large';
}

const Grid = (props: IProps) => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'grid';
  const cx = classnames.bind(styles);

  const {
    id,
    className,
    theme = 'none',
    children,
    type = 'section',
    display = 'flex',
    wrap = 'wrap',
    direction = 'row',
    content = 'center',
    align = 'center',
    gap = 0,
  } = props;

  // ************************************
  // Render
  // ************************************

  return (
    <>
      {type === 'main' && (
        <div
          id={id}
          className={cx(classPrefix, {
            [`${className}`]: className,
            [`${classPrefix}--${theme}`]: theme,
            [`${classPrefix}--${display}`]: display,
            [`${classPrefix}--${wrap}`]: wrap,
            [`${classPrefix}--${direction}`]: direction,
            [`${classPrefix}-content--${content}`]: content,
            [`${classPrefix}-align--${align}`]: align,
            [`${classPrefix}-gap--${gap}`]: gap,
          })}
        >
          {children}
        </div>
      )}
      {type === 'section' && (
        <section
          id={id}
          className={cx(classPrefix, {
            [`${className}`]: className,
            [`${classPrefix}--${theme}`]: theme,
            [`${classPrefix}--${display}`]: display,
            [`${classPrefix}--${wrap}`]: wrap,
            [`${classPrefix}--${direction}`]: direction,
            [`${classPrefix}-content--${content}`]: content,
            [`${classPrefix}-align--${align}`]: align,
            [`${classPrefix}-gap--${gap}`]: gap,
          })}
        >
          {children}
        </section>
      )}
      {type === 'nav' && (
        <nav
          id={id}
          className={cx(classPrefix, {
            [`${className}`]: className,
            [`${classPrefix}--${theme}`]: theme,
            [`${classPrefix}--${display}`]: display,
            [`${classPrefix}--${wrap}`]: wrap,
            [`${classPrefix}--${direction}`]: direction,
            [`${classPrefix}-content--${content}`]: content,
            [`${classPrefix}-align--${align}`]: align,
            [`${classPrefix}-gap--${gap}`]: gap,
          })}
        >
          {children}
        </nav>
      )}
      {type === 'article' && (
        <article
          id={id}
          className={cx(classPrefix, {
            [`${className}`]: className,
            [`${classPrefix}--${theme}`]: theme,
            [`${classPrefix}--${display}`]: display,
            [`${classPrefix}--${wrap}`]: wrap,
            [`${classPrefix}--${direction}`]: direction,
            [`${classPrefix}-content--${content}`]: content,
            [`${classPrefix}-align--${align}`]: align,
            [`${classPrefix}-gap--${gap}`]: gap,
          })}
        >
          {children}
        </article>
      )}
    </>
  );
};

export default Grid;
