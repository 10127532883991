import classnames from 'classnames/bind';
import { Theme, buttons, themeToString } from '../../models/Models';

import CustomLink from '../customLink/CustomLink';
import styles from './Button.module.scss';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  theme: Theme;
  type?: buttons;
  className?: string;
  id?: string;
  tooltip?: string;
  link?: string;
  children?: JSX.Element | string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  focused?: boolean;
  disabled?: boolean;
  active?: boolean;
}

/**
 * Button
 * @description Is used as a base for various button
 * types with core button functionality.
 * @param IProps props
 *  @param React.ReactNode props.children
 *  @param string props.id (optional)
 *  @param Theme theme
 *  @param string props.type (Default: action)
 *  @param string props.className (optional)
 *  @param string props.tooltip (optional)
 *  @param boolean props.disabled (Default: false)
 *  @param boolean props.active (Default: false)
 *  @param boolean props.focused (Default: false)
 *  @param function props.onClick
 *  @param function props.onMouseEnter
 *  @param function props.onMouseLeave
 * @return Button
 */
const Button = (props: ButtonProps) => {
  const {
    theme,
    type = 'action',
    id,
    className,
    tooltip,
    children,
    link,
    focused = false,
    active = false,
    disabled = false,
    onClick,
    onMouseEnter,
    onMouseLeave,
    ...rest
  } = props;

  // ************************************
  // Properties
  // ************************************

  const cx = classnames.bind(styles);
  const componentClass = 'button';

  // ************************************
  // Render
  // ************************************

  return (
    <>
      {!link && (
        <button
          type="button"
          id={id}
          className={cx(`${type}-${componentClass}`, {
            [`${className}`]: className,
            [`${type}-${componentClass}--${themeToString(theme)}`]:
              theme != null,
            [`${type}-${componentClass}::active`]: active,
            [`${type}-${componentClass}::focused`]: focused,
            [`${type}-${componentClass}--disabled`]: disabled,
          })}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={tooltip}
          disabled={disabled}
          {...rest}
        >
          {children}
        </button>
      )}
      {link && (
        <CustomLink
          id={id}
          className={cx(`${type}-${componentClass}`, {
            [`${className}`]: className,
            [`${type}-${componentClass}--${themeToString(theme)}`]:
              theme != null,
            [`${type}-${componentClass}::active`]: active,
            [`${type}-${componentClass}::focused`]: focused,
            [`${type}-${componentClass}--disabled`]: disabled,
          })}
          to={link}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          tooltip={tooltip}
          element={children}
          {...rest}
        />
      )}
    </>
  );
};

export default Button;
